<template lang="pug">
.absolute.bottom-5.top-5.left-5.z-30.bg-white.shadow.grid.grid-cols-2.pr-4.pl-6.pt-5.w-module(
  style='grid-template-rows: auto auto auto 1fr auto'
)
  .text-darkblue.col-start-1.col-end-2.text-xl.font-bold.whitespace-no-wrap.font-SourceSansPro {{ activeTabTitle }}
  .flex.justify-end.col-start-2.items-center
    img.ml-2.history-image(
      src='@/assets/icons/history.svg',
      @click='showHistoryChanges'
    )
    i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
      @click='handlClose'
    )

  .col-start-1.col-end-3.pb-3.mt-6.flex.justify-between
    skif-filter(
      v-if='activeTab === tabs.units',
      :placeholder='$t("search")',
      v-model='filterString',
      :filterableData='filterableData',
      :filterModel='searchOption',
      @filter='handleFilteredSearch',
      @loopclick='handleSearchClick',
      :sensor='isSensor',
      :sensortype='sensortype',
      @sensor='handlSensor',
      @lastTypeSearch='handlLastTypeSearch',
      @sensorChange='sensorChange'
    )

    skif-search.skif-search(
      v-else
      :placeholder='$t("search")'
      v-model='filterStrings'
      @searching="searching"
    )
    skif-button(variant='normal', v-if='arrayToDelete.length && activeTab !== tabs.locators', @click='cancel') {{ $t("btn.cancel") }}

    el-badge(
      v-if="activeTab === tabs.locators && checkedLocators.length",
      class="item",
      :value='locatorsSelectedLength'
    )
      skif-button(
        :disabled='disabledRole',
        @click='deleteUnits',
        class="button-square button-delete",
        type='button'
      )
        svg.group-hover_fill-fillTrash.transition-colors.mb-1.ease-in-out.duration-200(
          width='24',
          height='24',
          xmlns='http://www.w3.org/2000/svg',
          fill='#fafafa'
        )
          path(
            fill-rule='evenodd',
            clip-rule='evenodd',
            d='M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z'
          )
    skif-button(v-if="activeTab === tabs.locators", :disabled='disabledRoleLocators', @click='addUnit', class="button-square") +
    skif-button(v-else :disabled='disabledRole', @click='addUnit') {{ $t("add") }}

  .col-start-1.col-end-3.pb-3.mt-6.flex.justify-between
    span.flex.font-semibold.col-start-1.col-end-4.text-base.item-center.w-100
      .relative.mx-1.px-2
        .cursor-pointer.transition.duration-300(
          @click='activeTab = tabs.units',
          :style='activeTab === tabs.units ? { transform: "scale(1)" } : {}',
          :class='activeTab === tabs.units ? "text-headerText " : "text-annotationColor"'
        ) {{ $t("objects.objects") }}
        skif-badge(isObject, :value='filterDataUnits.length')
      .relative.mx-1.px-2
        .cursor-pointer.transition.duration-300(
          @click='activeTab = tabs.unitsgroups',
          :style='activeTab === tabs.unitsgroups ? { transform: "scale(1)" } : {}',
          :class='activeTab === tabs.unitsgroups ? "text-headerText " : "text-annotationColor"'
        ) {{ $t("objects.groups") }}
        skif-badge(isObject, :value='filterDataUnitgroups.length')
      .relative.mx-1.px-2
        .cursor-pointer.transition.duration-300(
          @click='activeTab = tabs.trailers',
          :style='activeTab === tabs.trailers ? { transform: "scale(1)" } : {}',
          :class='activeTab === tabs.trailers ? "text-headerText " : "text-annotationColor"'
        ) {{ $t("objects.trailers") }}
        skif-badge(isObject, :value='filterDataTrailers.length')
      .relative.mx-1.px-2(v-if="notRole")
        .cursor-pointer.transition.duration-300(
          @click='activeTab = tabs.locators',
          :style='activeTab === tabs.locators ? { transform: "scale(1)" } : {}',
          :class='activeTab === tabs.locators ? "text-headerText " : "text-annotationColor"'
        ) {{ $t("objects.locators") }}
        skif-badge(isObject, :value='filterDataLocators.length')
    span.absolute(
      v-if='arrayToDelete.length && activeTab !== tabs.locators',
      style='left: 2px; top: 142px'
    )
      el-tooltip(
        :open-delay="700"
          effect="dark"
          :content="$t('delete')"
          placement="bottom"
          :offset="2"
          :visible-arrow="false"
      )
        button.mr-4.focus_outline-none.relative.group(
          type='button',
          @click='deleteUnits'
        )
          Svg-trash(:arrayToDelete='arrayToDelete')

  .col-start-1.col-end-3.overflow-y-auto.my-2(
    @scroll.passive='handleScrollUnits',
    ref='scrolltable',
    v-show='activeTab === tabs.units'
  )
    .table.w-full.table-fixed
      .table-header-group.text-left
        .table-row
          th.bg-white.top-0.sticky.w-10
            skif-checkbox.p-2(
              @click.native='(event) => event.stopPropagation()',
              v-model='isAllCheckedUnits',
              :indeterminate='isIndeterminateUnits',
              :disabled='!filterDataUnits.length'
            )
          th.bg-white.top-0.sticky
            .flex.items-center.pb-2
              span.font-bold.text-darkblue.text-base.mr-2 {{ $t("objects.name.number") }}
              Svg-SortChange-units(
                :sort='sortPropertyUnits.name',
                :sortPropUnits='sortPropUnits',
                :sortOrderUnits='sortOrderUnits',
                @sortChangeUnits='sortChangeUnits(sortPropertyUnits.name)'
              )
          th.bg-white.top-0.sticky.w-48
            .flex.items-center.pb-2
              span.font-bold.text-darkblue.text-base.mr-2.truncate {{ $t("objects.device") }}
              Svg-SortChange-units(
                :sort='sortPropertyUnits.terminalType',
                :sortPropUnits='sortPropUnits',
                :sortOrderUnits='sortOrderUnits',
                @sortChangeUnits='sortChangeUnits(sortPropertyUnits.terminalType)'
              )

      .table-row-group
        .table-row(:style='{ height: this.topPaddingHeightUnits + "px" }')
        .table-row.cursor-pointer.hover_bg-hoverrow(
          v-for='unit in visibleItemsUnits',
          :key='unit.id',
          @click='handlEdit(unit)'
        )
          td.w-10
            skif-checkbox.p-2(
              @click.native='(event) => event.stopPropagation()',
              v-model='checkedUnits',
              :label='unit.id',
              :showLabel='false'
            )
          td.truncate.pr-2
            el-tooltip(
              :open-delay='700',
              effect='dark',
              :content='unit.name',
              placement='bottom',
              :offset='0',
              :visible-arrow='false'
            )
              span.leading-10.font-bold.text-sm.transition-colors.duration-300(
                :class='checkedUnits.includes(unit.id) ? "text-darkblue" : "text-annotationColor"'
              ) {{ unit.name }}
          td.truncate
            span.leading-10.font-bold.text-sm.transition-colors.duration-300(
              :class='checkedUnits.includes(unit.id) ? "text-darkblue" : "text-annotationColor"'
            ) {{ unit.terminal_type.value }}
        .table-row(:style='{ height: this.bottomPaddingHeightUnits + "px" }')
  .col-start-1.col-end-3.overflow-y-auto.my-2(
    @scroll.passive='handleScrollUnitsgroups',
    v-show='activeTab === tabs.unitsgroups'
  )
    .table.w-full.table-fixed
      .table-header-group.text-left
        .table-row
          .table-cell.bg-white.top-0.sticky.z-10.w-10
            skif-checkbox.p-2(
              @click.native='(event) => event.stopPropagation()',
              v-model='isAllCheckedUnitsgroups',
              :indeterminate='isIndeterminateUnitsgroups',
              :disabled='!filterDataUnitgroups.length'
            )
          .table-cell.bg-white.top-0.sticky
            .flex.items-center.pb-2
              span.font-bold.text-darkblue.text-base.mr-2 {{ $t("name") }}
              Svg-SortChange-unitsgroups(
                :sort='sortPropertyUnitsGroup.name',
                :sortPropUnitsgroup='sortPropUnitsgroup',
                :sortOrderUnitsgroup='sortOrderUnitsgroup',
                @sortChangeUnitsgroups='sortChangeUnitsgroups(sortPropertyUnitsGroup.name)'
              )
          .table-cell.bg-white.top-0.sticky.w-16
            .flex.items-center.pb-2
              SvgSortIcons(:shape='"car"')
              Svg-SortChange-unitsgroups(
                :sort='sortPropertyUnitsGroup.units',
                :sortPropUnitsgroup='sortPropUnitsgroup',
                :sortOrderUnitsgroup='sortOrderUnitsgroup',
                @sortChangeUnitsgroups='sortChangeUnitsgroups(sortPropertyUnitsGroup.units)'
              )

          .table-cell.bg-white.top-0.sticky.w-16
            .flex.items-center.pb-2
              SvgSortIcons(:shape='"driver"')
              Svg-SortChange-unitsgroups(
                :sort='sortPropertyUnitsGroup.users',
                :sortPropUnitsgroup='sortPropUnitsgroup',
                :sortOrderUnitsgroup='sortOrderUnitsgroup',
                @sortChangeUnitsgroups='sortChangeUnitsgroups(sortPropertyUnitsGroup.users)'
              )

      .table-row-group
        .table-row(
          :style='{ height: this.topPaddingHeightUnitsgroups + "px" }'
        )
        .table-row.cursor-pointer.hover_bg-hoverrow(
          v-for='unitsgroup in visibleItemsUnitsgroups',
          :key='unitsgroup.id',
          @click='$emit("update-unitsgroup", { unitsgroup: unitsgroup, isUnits: true })'
        )
          .table-cell.w-10
            skif-checkbox.p-2(
              @click.native='(event) => event.stopPropagation()',
              v-model='checkedUnitsgroups',
              :label='unitsgroup.id',
              :showLabel='false'
            )
          .table-cell.truncate.pr-2
            el-tooltip(
              :open-delay='700',
              effect='dark',
              :content='unitsgroup.name',
              placement='bottom',
              :offset='0',
              :visible-arrow='false'
            )
              span.leading-10.font-bold.text-sm.transition-colors.duration-300(
                :class='checkedUnitsgroups.includes(unitsgroup.id) ? "text-darkblue" : "text-annotationColor"'
              ) {{ unitsgroup.name }}
          .table-cell.truncate
            span.leading-10.font-bold.text-sm.transition-colors.duration-300(
              :class='checkedUnitsgroups.includes(unitsgroup.id) ? "text-darkblue" : "text-annotationColor"'
            ) {{ unitsgroup.units.length }}
          .table-cell.truncate
            span.leading-10.font-bold.text-sm.transition-colors.duration-300(
              :class='checkedUnitsgroups.includes(unitsgroup.id) ? "text-darkblue" : "text-annotationColor"'
            ) {{ unitsgroup.users.length }}
        .table-row(
          :style='{ height: this.bottomPaddingHeightUnitsgroups + "px" }'
        )
  .col-start-1.col-end-3.overflow-y-auto.my-2(
    @scroll.passive='handleScrollTrailers',
    v-show='activeTab === tabs.trailers'
  )
    .table.w-full.table-fixed
      .table-header-group.text-left
        .table-row
          .table-cell.bg-white.top-0.sticky.z-10.w-10
            skif-checkbox.p-2(
              @click.native='(event) => event.stopPropagation()',
              v-model='isAllCheckedTrailers',
              :indeterminate='isIndeterminateTrailer',
              :disabled='!filterDataTrailers.length'
            )
          .table-cell
            .flex.items-center.pb-2
              span.font-bold.text-darkblue.text-base.mr-2 {{ $t("name") }}
              Svg-SortChange-unitsTrailer(
                :sort='sortPropertyTrailers.name',
                :sortPropTrailer='sortPropTrailer',
                :sortOrderTrailer='sortOrderTrailer',
                @sortChangeTrailers='sortChangeTrailers(sortPropertyTrailers.name)'
              )
          .table-cell
            .flex.items-center.pb-2
              span.font-bold.text-darkblue.text-base.mr-2.truncate {{ $t("code") }}
              Svg-SortChange-unitsTrailer(
                :sort='sortPropertyTrailers.code',
                :sortPropTrailer='sortPropTrailer',
                :sortOrderTrailer='sortOrderTrailer',
                @sortChangeTrailers='sortChangeTrailers(sortPropertyTrailers.code)'
              )

      .table-row-group
        .table-row(:style='{ height: this.topPaddingHeightTrailers + "px" }')
        .table-row.cursor-pointer.hover_bg-hoverrow(
          v-for='trailer in visibleItemsTrailers',
          :key='trailer.id',
          @click='$emit("update-trailer", trailer.id)'
        )
          .table-cell.w-10
            skif-checkbox.p-2(
              @click.native='(event) => event.stopPropagation()',
              v-model='checkedTrailers',
              :label='trailer.id',
              :showLabel='false'
            )
          .table-cell.truncate.pr-2
            el-tooltip(
              :open-delay='700',
              effect='dark',
              :content='trailer.name',
              placement='bottom',
              :offset='0',
              :visible-arrow='false'
            )
              span.leading-10.font-bold.text-sm.transition-colors.duration-300(
                :class='checkedTrailers.includes(trailer.id) ? "text-darkblue" : "text-annotationColor"'
              ) {{ trailer.name }}
          .table-cell.truncate
            span.leading-10.font-bold.text-sm.transition-colors.duration-300(
              :class='checkedTrailers.includes(trailer.id) ? "text-darkblue" : "text-annotationColor"'
            ) {{ trailer.code }}
        .table-row(
          :style='{ height: this.bottomPaddingHeightTrailers + "px" }'
        )
    form(
      v-if="isPayementReady"
      ref="payment"
      method="post"
      :action="currentActiveBilling"
    )
      input(
        v-for="(value, name) in readyOrder"
        type="hidden"
        :name="name"
        :value="value"
      )
  .col-start-1.col-end-3.overflow-y-auto.my-2(
    @scroll.passive='handleScrollLocators',
    v-show='activeTab === tabs.locators'
  )
    .table.w-full.table-fixed
        .table-header-group.text-left
          .table-row.bg-white.sticky.top-0.z-20
            .table-cell.bg-white.top-0.z-10.w-8
              skif-checkbox.p-2(
                @click.native='(event) => event.stopPropagation()',
                v-model='isAllCheckedLocators',
                :indeterminate='isIndeterminateLocator',
                :disabled='!filterDataLocators.length'
              )
            .table-cell
              .flex.items-center.pb-2
                span.font-bold.text-darkblue.text-base.mr-2 {{ $t("objects.locator.access-object") }}
                Svg-SortChange-unitsLocator(
                  :sort='sortPropertyLocators.name',
                  :sortPropLocator="sortPropLocator",
                  :sortOrderLocator="sortOrderLocator",
                  @sortChangeLocators='sortChangeLocators(sortPropertyLocators.name)'
                )
            .table-cell.status-cell
              .flex.items-center.pb-2
                span.font-bold.text-darkblue.text-base.mr-2.truncate {{ $t("objects.locator.status") }}
                Svg-SortChange-unitsLocator(
                  :sort='sortPropertyLocators.active',
                  :sortPropLocator='sortPropLocator',
                  :sortOrderLocator='sortOrderLocator',
                  @sortChangeLocators='sortChangeLocators(sortPropertyLocators.active)'
                )
            .table-cell.bg-white.top-0.w-12
            .table-cell.bg-white.top-0.w-10

        .table-row-group
          .table-row(:style='{ height: this.topPaddingHeightLocators + "px" }')
          .table-row.hover_bg-hoverrow(
            v-for='(locator, index) in visibleItemsLocators',
            :key='locator.id',
          )
            .table-cell.w-8
              skif-checkbox.p-1.pl-2(
                @click.native='(event) => event.stopPropagation()',
                v-model='checkedLocators',
                :label='locator.id',
                :showLabel='false'
              )
            .table-cell
              p.leading-4.truncate.font-bold.text-sm.transition-colors.duration-300(
                :class='checkedLocators.includes(locator.id) ? "text-darkblue" : "text-annotationColor"'
              ) {{ locator.unit.name || 'Название объекта' }}
              span.text-xs.text-narrow.font-SourceSansPro.text-dates {{ locator.date_from.replaceAll('-', '/') }} - {{ locator.date_to.replaceAll('-', '/') }}
            .table-cell
              span.leading-10.font-bold.text-sm.transition-colors.duration-300 {{ locatorStatus(locator) }}
            .table-cell.w-12
              skif-switch(
                v-model="locator.is_active"
                @click.native="(event) => event.stopPropagation()"
                @change="changeActivity(locator.id, locator.is_active)"
              )
            .table-cell.w-10
              el-popover.flex.flex-col(
                ref="locator-actions-popover"
                trigger="click"
              )
                skif-button(
                  class="px-2 mb-2 w-100",
                  @click='copyLinkToClipboard(locator.id, index)',
                ) Копировать ссылку
                skif-button(
                  class="w-100"
                  @click='updateLocatorEmit(locator.id, index)',
                ) Редактировать
                button(
                  type="button",
                  slot="reference"
                )
                  svg(
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  )
                    path(
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.2501 7.5C13.4876 7.5 14.5001 6.4875 14.5001 5.25C14.5001 4.0125 13.4876 3 12.2501 3C11.0126 3 10.0001 4.0125 10.0001 5.25C10.0001 6.4875 11.0126 7.5 12.2501 7.5V7.5ZM12.25 9.74966C11.0125 9.74966 10 10.7622 10 11.9997C10 13.2372 11.0125 14.2497 12.25 14.2497C13.4875 14.2497 14.5 13.2372 14.5 11.9997C14.5 10.7622 13.4875 9.74966 12.25 9.74966V9.74966ZM12.25 16.5002C11.0125 16.5002 10 17.5127 10 18.7502C10 19.9877 11.0125 21.0002 12.25 21.0002C13.4875 21.0002 14.5 19.9877 14.5 18.7502C14.5 17.5127 13.4875 16.5002 12.25 16.5002V16.5002Z"
                      fill="#5477A9"
                    )
          .table-row(
            :style='{ height: this.bottomPaddingHeightLocators + "px" }'
          )
  transition(name='fading', mode='in-out')
    billingForm(
      v-if='isBilling && activeTab === tabs.units',
      @close='isBilling = false',
      @unblock='unblockCompany'
    )
</template>

<script>
import virtualScrollerLocators from '@/mixins/virtualScrollerLocators.js'
import virtualScrollerUnits from '@/mixins/virtualScrollerUnits.js'
import _ from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  sortOrder,
  sortPropertyLocators,
  sortPropertyTrailers,
  sortPropertyUnits,
  sortPropertyUnitsGroup,
  tabs
} from './constants.js'
import { copyTextToClipboard } from '@/utils/helpers';

const currentUrl = window.location.href

export default {
  name: 'ListUnits',
  components: {
    billingForm: () => import('./subComponents/billingForm.vue'),
    SvgTrash: () => import('./unitsSvg/Svg-trash.vue'),
    SvgSortIcons: () => import('./unitsSvg/Svg-sortIcons.vue'),
    SvgSortChangeUnits: () => import('./unitsSvg/Svg-SortChange-units.vue'),
    SvgSortChangeUnitsgroups: () =>
      import('./unitsSvg/Svg-SortChange-unitsgroups.vue'),
    SvgSortChangeUnitsTrailer: () =>
      import('./unitsSvg/Svg-SortChange-unitsTrailer.vue'),
    SvgSortChangeUnitsLocator: () =>
      import('./unitsSvg/Svg-SortChange-unitsLocator.vue')
  },
  mixins: [virtualScrollerUnits, virtualScrollerLocators],
  props: {
    activeTabUnits: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isPayementReady: false,
      filterableData: [
        { key: 'Имя', value: 'name' },
        { key: 'IMEI', value: 'imei' },
        { key: 'Телефоны', value: 'phonenumber' },
        { key: 'Доп. поля', value: 'custom_fields' },
        { key: 'Имена датчиков', value: 'sensors.name' },
        { key: 'Типы датчиков', value: 'sensors.type.key' },
        { key: 'Параметр датчиков', value: 'sensors.param' },
        { key: 'События', value: 'events' }
      ],
      filterableDataLocators: [
        { key: 'Название объекта', value: 'unit.name' },
        { key: 'IMEI', value: 'unit.imei' }
      ],
      searchOption: this.lastTypeSearch,
      activeTab: tabs.units,
      isSensor: false,
      isBilling: false,
      tabs,
      // UNITS
      filterStringUnits: '',
      checkedUnits: [],
      sortOrderUnits: '',
      sortPropUnits: '',
      sortPropertyUnits,
      // UNITSGROUPS
      filterStringUnitsgroups: '',
      checkedUnitsgroups: [],
      sortOrderUnitsgroup: '',
      sortPropUnitsgroup: '',
      sortPropertyUnitsGroup,
      // TRAILERS
      filterStringTrailers: '',
      checkedTrailers: [],
      sortOrderTrailer: '',
      sortPropTrailer: '',
      sortPropertyTrailers,
      filterString: '',
      // LOCATORS
      filterStringLocators: '',
      checkedLocators: [],
      sortOrderLocator: '',
      sortPropLocator: '',
      sortPropertyLocators
    }
  },

  computed: {
    // ...mapGetters('login', ['role', 'disabledRole', 'notRole']),
    ...mapGetters({
      role: 'login/role',
      disabledRole: 'login/disabledRole',
      notRole: 'login/notRole',
      allUnits: 'units/allUnits',
      terminals: 'terminal/terminals',
      tariffType: 'properties/tariffType'
    }),
    ...mapState({ sensortype: (state) => state.dictionary.sensor_type }),
    ...mapState('login', {
      activeCompany: (state) => state.me
    }),
    ...mapState('billing', {
      order: (state) => state.order
    }),
    ...mapState('units', {
      oldunits: (state) => state.units,
      unitsGroups: (state) => state.unitsGroups,
      lastSearchKey: (state) => state.lastSearchKey,
      lastTypeSearch: (state) => state.lastTypeSearch,
      units: (state) => state.defaultUnits,
      recoveryId: (state) => state.recoveryId
    }),
    ...mapState('trailers', {
      trailers: (state) => state.trailers,
      recoveryIdTrailer: (state) => state.recoveryId
    }),
    ...mapState('locators', {
      locators: (state) => state.locators,
      recoveryIdLocator: (state) => state.recoveryId
    }),
    arrayToDelete: {
      get() {
        switch (this.activeTab) {
          case tabs.units:
            return this.checkedUnits
          case tabs.unitsgroups:
            return this.checkedUnitsgroups
          case tabs.trailers:
            return this.checkedTrailers
          case tabs.locators:
            return this.checkedLocators
          default:
            return this.checkedUnits
        }
      },
      set(value) {
        switch (this.activeTab) {
          case tabs.units:
            this.checkedUnits = value
          case tabs.unitsgroups:
            this.checkedUnitsgroups = value
          case tabs.trailers:
            this.checkedTrailers = value
          case tabs.locators:
            this.checkedLocators = value
        }
      }
    },
    filterStrings: {
      get() {
        switch (this.activeTab) {
          case tabs.unitsgroups:
            return this.filterStringUnitsgroups
          case tabs.trailers:
            return this.filterStringTrailers
          case tabs.locators:
            return this.filterStringLocators
          default:
            return this.filterStringUnitsgroups
        }
      },
      set(value) {
        switch (this.activeTab) {
          case tabs.unitsgroups:
            this.filterStringUnitsgroups = value
          case tabs.trailers:
            this.filterStringTrailers = value
          case tabs.locators:
            this.filterStringLocators = value
        }
      }
    },
    filterDataUnits() {
      const units = this.allUnits
      const isNameSorting = this.sortPropUnits === sortPropertyUnits.name
      const isTerminalTypeSorting =
        this.sortPropUnits === sortPropertyUnits.terminalType

      return units.sort((a, b) => {
        if (isNameSorting) {
          return this.getFilterConditionByCompare(
            a[this.sortPropUnits],
            b[this.sortPropUnits],
            this.sortOrderUnits
          )
        }

        if (isTerminalTypeSorting) {
          return this.getFilterConditionByCompare(
            a[this.sortPropUnits].value,
            b[this.sortPropUnits].value,
            this.sortOrderUnits
          )
        }

        return 0
      })
    },
    filterDataUnitgroups() {
      const unitsGroups = this.unitsGroups.filter((unitsgroup) =>
        JSON.stringify(unitsgroup)
          .toLowerCase()
          .includes(this.filterStringUnitsgroups.toLowerCase())
      )

      const isNameSorting =
        this.sortPropUnitsgroup === sortPropertyUnitsGroup.name
      const isUnitsSorting =
        this.sortPropUnitsgroup === sortPropertyUnitsGroup.units
      const isUsersSorting =
        this.sortPropUnitsgroup === sortPropertyUnitsGroup.users

      return unitsGroups.sort((a, b) => {
        if (isNameSorting) {
          return this.getFilterConditionByCompare(
            a[this.sortPropUnitsgroup],
            b[this.sortPropUnitsgroup],
            this.sortOrderUnitsgroup
          )
        }

        if (isUnitsSorting || isUsersSorting) {
          return this.getFilterConditionBySubtraction(
            a[this.sortPropUnitsgroup],
            b[this.sortPropUnitsgroup],
            this.sortOrderUnitsgroup
          )
        }

        return 0
      })
    },
    filterDataTrailers() {
      const trailers = this.trailers.filter((trailer) =>
        JSON.stringify(trailer)
          .toLowerCase()
          .includes(this.filterStringTrailers.toLowerCase())
      )

      return trailers.sort((a, b) =>
        this.getFilterConditionByCompare(
          a[this.sortPropTrailer],
          b[this.sortPropTrailer],
          this.sortOrderTrailer
        )
      )
    },
    filterDataLocators() {
      const locators = this.locators.filter((locator) =>
        JSON.stringify(locator)
          .toLowerCase()
          .includes(this.filterStringLocators.toLowerCase())
      )

      const isNameSorting = this.sortPropLocator === sortPropertyLocators.name
      const isActiveSorting =
        this.sortPropLocator === sortPropertyLocators.active

      return locators.sort((a, b) => {
        if (isNameSorting) {
          return this.getFilterConditionByCompare(
            a.unit[this.sortPropLocator],
            b.unit[this.sortPropLocator],
            this.sortOrderLocator
          )
        }

        if (isActiveSorting) {
          return this.getFilterConditionByCompare(
            Number(a.is_active),
            Number(b.is_active),
            this.sortOrderLocator
          )
        }

        return 0
      })
    },
    isIndeterminateUnits() {
      return (
        this.checkedUnits.length > 0 &&
        this.checkedUnits.length < this.filterDataUnits.length
      )
    },
    isIndeterminateUnitsgroups() {
      return (
        this.checkedUnitsgroups.length > 0 &&
        this.checkedUnitsgroups.length < this.filterDataUnitgroups.length
      )
    },
    isIndeterminateTrailer() {
      return (
        this.checkedTrailers.length > 0 &&
        this.checkedTrailers.length < this.filterDataTrailers.length
      )
    },
    isIndeterminateLocator() {
      return (
        this.checkedLocators.length > 0 &&
        this.checkedLocators.length < this.filterDataLocators.length
      )
    },
    unitIds() {
      return this.filterDataUnits.map((k) => k.id)
    },
    unitsgroupIds() {
      return this.filterDataUnitgroups.map((k) => k.id)
    },
    trailersIds() {
      return this.filterDataTrailers.map((k) => k.id)
    },
    locatorsIds() {
      return this.filterDataLocators.map((l) => l.id)
    },
    isAllCheckedUnits: {
      get() {
        return (
          this.checkedUnits.length >= this.filterDataUnits.length &&
          this.filterDataUnits.length
        )
      },
      set(value) {
        this.checkedUnits = value ? this.unitIds : []
      }
    },
    isAllCheckedUnitsgroups: {
      get() {
        return (
          this.checkedUnitsgroups.length >= this.filterDataUnitgroups.length &&
          this.filterDataUnitgroups.length
        )
      },
      set(value) {
        this.checkedUnitsgroups = value ? this.unitsgroupIds : []
      }
    },
    isAllCheckedTrailers: {
      get() {
        return (
          this.checkedTrailers.length >= this.filterDataTrailers.length &&
          this.filterDataTrailers.length
        )
      },
      set(value) {
        this.checkedTrailers = value ? this.trailersIds : []
      }
    },
    isAllCheckedLocators: {
      get() {
        return (
          this.checkedLocators.length >= this.filterDataLocators.length &&
          this.filterDataLocators.length
        )
      },
      set(value) {
        this.checkedLocators = value ? this.locatorsIds : []
      }
    },
    locatorsSelectedLength() {
      return this.checkedLocators.length
    },
    activeTabTitle() {
      switch (this.activeTab) {
        case tabs.units:
          return this.$t('objects.title')
        case tabs.unitsgroups:
          return this.$t('objects.titlegroups')
        case tabs.trailers:
          return this.$t('objects.titleTrailers')
        case tabs.locators:
          return this.$t('objects.titleLocators')
        default:
          return this.$t('objects.title')
      }
    },

    disabledRoleLocators() {
      const notAllowedRoles = ['READER', 'EDITOR', 'OBSERVER', 'OPERATOR']

      return notAllowedRoles.includes(this.role)
    },

    tariffPrice() {
      return this.activeCompany.active_company.billing.tariff.price
    },

    currentActiveBilling() {
      if (
        this.activeCompany.active_company.billing.system.type === 'MODULBANK'
      ) {
        return 'https://pay.modulbank.ru/pay'
      }
      return 'https://api.paybox.money/payment.php'
    },

    readyOrder() {
      const arr = {}
      for (const i in this.order) {
        arr[i] =
          typeof this.order[i] === 'object'
            ? JSON.stringify(this.order[i])
            : this.order[i]
      }
      return arr
    }
  },
  watch: {
    order() {
      this.isPayementReady = true
    },
    lastSearchKey(val) {
      this.filterString = val
    },
    '$parent.spinnerLoading': function () {
      this.reset()
    },
    activeTab(val) {
      if (val !== tabs.units) {
        this.isSensor = false
        this.$store.dispatch('units/HANDLE_KEYSEARCH', '')
        this.$store.dispatch('units/HANDLE_TYPESEARCH', '')
        this.checkedUnits = []
        this.checkedUnitsgroups = []
        this.checkedTrailers = []
        this.checkedLocators = []
      } else {
        this.filterString = this.lastSearchKey
      }
    }
  },
  methods: {
    ...mapActions({
      createNewOrder: 'billing/CREATE_NEWORDER',
      createOrder: 'billing/CREATE_ORDER'
    }),
    ...mapMutations({
      setBillingInfo: 'login/setBillingInfo'
    }),
    searching(val) {
      this.filterStrings = val
    },
    locatorStatus(locator) {
      if (locator.is_active) {
        return 'Активна'
      }

      return 'Не активна'
    },
    closeDeletedNotification() {
      setTimeout(() => {
        this.$notify.closeAll()
      }, 10000)
    },
    showHistoryChanges() {
      if (this.notRole) {
        const objects = { objects: this.activeTab }
        this.$emit('show-history', objects)
      }
    },
    unblockCompany(amount) {
      const formData = {
        amount: amount.amount,
        // success_url: 'https://dev.skif.pro/',
        success_url: currentUrl,
        skip_init_payment: true
      }

      const formDataNewTariff = {
        tariff: {
          units: amount.amount / this.tariffPrice,
          length: 30,
          price: this.tariffPrice
        },
        // success_url: 'https://dev.skif.pro/',
        success_url: currentUrl
      }
      this.createNewOrder(formDataNewTariff)
        .then((response) => {
          if (response.status === 200) {
            this.setBillingInfo(response.data)
            return this.createOrder(formData)
          }
          throw new Error('Error')
        })
        .then((response) => {
          if (
            this.activeCompany.active_company.billing.tariff.type ===
              'charge_sent_units' ||
            this.activeCompany.active_company.billing.company_status === 'TRIAL'
          )
            return
          if (response.status === 200) {
            this.$refs.payment.submit()
          } else {
            this.$notify.error({
              duration: 5000,
              title: `${this.$t('error')} \u{1f62a}`,
              message: response
            })
          }
        })
        .catch((error) => {
          this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: error
          })
          return 'Error'
        })
    },
    changeActivity(id) {
      const locator = this.filterDataLocators.find((l) => l.id === id)

      if (locator) {
        this.$store.dispatch('locators/UPDATE', locator)
      }
    },
    sensorChange() {
      this.reset()
    },
    handlLastTypeSearch(val) {
      this.searchOption = val
      if (val === 'sensors.type.key') {
        this.$store.commit('units/SET_OLDUNITS')
      }
    },
    handlEdit: _.debounce(
      function (val) {
        this.$emit('update-unit', { unitId: val.id, fromModule: 'units' })
        this.$store.dispatch('units/HANDLE_TYPESEARCH', this.searchOption)
      },
      200,
      {
        leading: true,
        trailing: false
      }
    ),
    handlSensor(val) {
      const formData = {
        conditions: [
          {
            field: 'sensors.type.key',
            value: val
          }
        ],
        fields: ['id']
      }
      this.$store.dispatch('units/SEARCH_UNITSENSOR', { formData })
    },
    handlClose() {
      this.$emit('close')
      this.$store.dispatch('units/HANDLE_KEYSEARCH', '')
      this.$store.dispatch('units/HANDLE_TYPESEARCH', '')
      this.$store.dispatch('units/SEARCH_UNIT', '')
      this.$store.commit('units/RESET_SEARCH_UNITS')
    },
    handleSearchClick() {
      let value

      switch (this.activeTab) {
        case tabs.units:
          value = this.filterStringUnits
          break
        case tabs.unitsgroups:
          value = this.filterStringUnitsgroups
          break
        case tabs.trailers:
          value = this.filterStringTrailers
          break
        case tabs.locators:
          value = this.filterStringLocators
          break
      }

      const formData = {
        conditions: [
          {
            field: this.searchOption,
            value: this.filterString
          }
        ],
        fields: ['id']
      }

      switch (this.activeTab) {
        case tabs.units:
          if (this.filterString.length > 0) {
            this.$store.dispatch('units/SEARCH_UNIT', { formData })
            this.$store.dispatch('units/HANDLE_KEYSEARCH', this.filterString)
            this.checkedUnits = []
            this.checkedUnitsgroups = []
            this.checkedTrailers = []
            this.checkedLocators = []
          } else {
            this.$store.commit('units/SET_OLDUNITS')
          }
          break
        case tabs.unitsgroups:
          this.filterStringUnitsgroups = value
          break
        case tabs.trailers:
          this.filterStringTrailers = value
          break
        case tabs.locators:
          this.filterStringLocators = value
          break
      }
    },
    handleFilteredSearch(val) {
      this.searchOption = val
      if (val === 'sensors.type.key') {
        this.isSensor = true
        this.checkedUnits = []
        this.checkedUnitsgroups = []
        this.checkedTrailers = []
        this.checkedLocators = []
      } else {
        this.isSensor = false
      }
    },
    sortChangeTrailers(prop) {
      if (this.sortPropTrailer === prop) {
        if (this.sortOrderTrailer === sortOrder.asc) {
          this.sortOrderTrailer = sortOrder.desc
        } else if (this.sortOrderTrailer === sortOrder.desc) {
          this.sortOrderTrailer = ''
          this.sortPropTrailer = ''
        } else {
          this.sortOrderTrailer = sortOrder.asc
        }
      } else {
        this.sortPropTrailer = prop
        this.sortOrderTrailer = sortOrder.asc
      }
    },
    sortChangeLocators(prop) {
      if (this.sortPropLocator === prop) {
        if (this.sortOrderLocator === sortOrder.asc) {
          this.sortOrderLocator = sortOrder.desc
        } else if (this.sortOrderLocator === sortOrder.desc) {
          this.sortOrderLocator = ''
          this.sortPropLocator = ''
        } else {
          this.sortOrderLocator = sortOrder.asc
        }
      } else {
        this.sortPropLocator = prop
        this.sortOrderLocator = sortOrder.asc
      }
    },
    sortChangeUnitsgroups(prop) {
      if (this.sortPropUnitsgroup === prop) {
        if (this.sortOrderUnitsgroup === sortOrder.asc) {
          this.sortOrderUnitsgroup = sortOrder.desc
        } else if (this.sortOrderUnitsgroup === sortOrder.desc) {
          this.sortOrderUnitsgroup = ''
          this.sortPropUnitsgroup = ''
        } else {
          this.sortOrderUnitsgroup = sortOrder.asc
        }
      } else {
        this.sortPropUnitsgroup = prop
        this.sortOrderUnitsgroup = sortOrder.asc
      }
    },
    sortChangeUnits(prop) {
      if (this.sortPropUnits === prop) {
        if (this.sortOrderUnits === sortOrder.asc) {
          this.sortOrderUnits = sortOrder.desc
        } else if (this.sortOrderUnits === sortOrder.desc) {
          this.sortOrderUnits = ''
          this.sortPropUnits = ''
        } else {
          this.sortOrderUnits = sortOrder.asc
        }
      } else {
        this.sortPropUnits = prop
        this.sortOrderUnits = sortOrder.asc
      }
    },
    closeLocatorPopover(index) {
      this.$refs['locator-actions-popover'][index].showPopper = false
    },
    async copyLinkToClipboard(locatorId, index) {
      const link = `https://${window.location.host}/locator?id=${locatorId}`
      await copyTextToClipboard(link)

      this.closeLocatorPopover(index)

      this.$notify.success({
        message: this.$t('objects.locator.copy-link'),
        position: 'top-left'
      })
    },
    updateLocatorEmit(locatorID, index) {
      this.closeLocatorPopover(index)

      this.$emit('update-locator', locatorID)
    },
    addUnit() {
      const currentTariff = this.activeCompany.active_company.billing.tariff
      if (!currentTariff) return

      switch (this.activeTab) {
        case tabs.units:
          if (
            currentTariff.price > 0 &&
            (currentTariff.units === 0 ||
              this.units.length >= currentTariff.units)
          ) {
            this.isBilling = true
            break
          }
          this.$emit('create-unit')
          break
        case tabs.unitsgroups:
          this.$emit('create-unitsgroup')
          break
        case tabs.trailers:
          this.$emit('create-trailer')
          break
        default:
          this.$emit('create-locator')
      }
    },
    cancel() {
      this.arrayToDelete = []
    },
    recoverItems(items, type) {
      let recoveryId = ''
      if (type === tabs.trailers) {
        recoveryId = this.recoveryIdTrailer
      } else if (type === tabs.locators) {
        recoveryId = this.recoveryIdLocator
      } else {
        recoveryId = this.recoveryId.id
      }

      const recoveryObject = {
        id: recoveryId,
        itemsDeleted: items,
        type
      }

      if (type === tabs.trailers) {
        this.$store.dispatch('trailers/RECOVER_TRAILERS', recoveryObject)
      } else if (type === tabs.locators) {
        this.$store.dispatch('locators/RECOVER_LOCATORS', recoveryObject)
      } else {
        this.$store.dispatch('units/RECOVER_ITEMS', recoveryObject)
      }

      this.$notify.closeAll()
    },

    deleteLocators() {
      const locatorsToDelete = this.filterDataLocators.filter((el) =>
        this.checkedLocators.some((id) => el.id === id)
      )

      const locatorsToDeleteIds = locatorsToDelete.map((l) => l.id)

      this.$store.dispatch('locators/DELETE', locatorsToDeleteIds).then(() => {
        this.cancel()
      })

      this.$notify({
        dangerouslyUseHTMLString: true,
        message: `<div >
                      <span class="" > ${
                        locatorsToDeleteIds.length <= 1
                          ? this.$t('one_element_deleted')
                          : `${locatorsToDeleteIds.length} ${this.$t(
                              'already_deleted_elements'
                            )}`
                      } </span>
                    </div>`,
        duration: 3000,
        position: 'bottom-left',
        customClass: 'confirmation'
      })
    },
    deleteUnits() {
      const currentElements = this.arrayToDelete

      const someUnits = this.filterDataUnits.filter((el) =>
        this.arrayToDelete.some((id) => el.id === id)
      )
      const someUnitsGroups = this.filterDataUnitgroups.filter((el) =>
        this.arrayToDelete.some((id) => el.id === id)
      )
      const someUnitsTrailers = this.filterDataTrailers.filter((el) =>
        this.arrayToDelete.some((id) => el.id === id)
      )
      const someUnitsLocators = this.filterDataLocators.filter((el) => {
        return this.arrayToDelete.some((id) => el.id === id)
      })

      this.$notify({
        dangerouslyUseHTMLString: true,
        message: `<div >
                      <span class="" > ${
                        currentElements.length <= 1
                          ? this.$t('one_element_deleted')
                          : `${currentElements.length} ${this.$t(
                              'already_deleted_elements'
                            )}`
                      } </span>
                      <span id='confirm' class='cursor-pointer cancelConfirm text-annotationColor'>${this.$t(
                        'cancel'
                      )}</span>
                    </div>`,
        duration: 10000,
        position: 'bottom-left',
        customClass: 'confirmation'
      })

      this.closeDeletedNotification()
      const confirmation = document.getElementById('confirm')

      switch (this.activeTab) {
        case tabs.units:
          this.$store
            .dispatch('units/DELETE_UNIT', this.arrayToDelete)
            .then(() => {
              this.cancel()
            })
          confirmation.addEventListener(
            'click',
            () => {
              this.recoverItems(someUnits, tabs.units)
            },
            false
          )
          break
        case tabs.unitsgroups:
          this.$store
            .dispatch('units/DELETE_GROUPUNIT', this.arrayToDelete)
            .then(() => {
              this.cancel()
            })
          confirmation.addEventListener(
            'click',
            () => {
              this.recoverItems(someUnitsGroups, tabs.unitsgroups)
            },
            false
          )
          break
        case tabs.trailers:
          this.$store
            .dispatch('trailers/DELETE', this.arrayToDelete)
            .then(() => {
              this.cancel()
            })
          confirmation.addEventListener(
            'click',
            () => {
              this.recoverItems(someUnitsTrailers, tabs.trailers)
            },
            false
          )
          break
        case tabs.locators:
          this.$store
            .dispatch('locators/DELETE', this.arrayToDelete)
            .then(() => {
              this.cancel()
            })
          confirmation.addEventListener(
            'click',
            () => {
              this.recoverItems(someUnitsLocators, tabs.locators)
            },
            false
          )
          break
      }
    },
    reset() {
      this.filterStringUnits = ''
      this.filterStringUnitsgroups = ''
      this.filterStringTrailers = ''
      this.filterStringLocators = ''
      this.checkedUnits = []
      this.checkedUnitsgroups = []
      this.checkedTrailers = []
      this.checkedLocators = []
    },
    getFilterConditionByCompare(a, b, sortOrderValue) {
      if (sortOrderValue === sortOrder.asc) {
        if (a > b) return 1
        if (b > a) return -1
      }

      if (sortOrderValue === sortOrder.desc) {
        if (a > b) return -1
        if (b > a) return 1
      }

      return 0
    },
    getFilterConditionBySubtraction(a, b, sortOrderValue) {
      if (sortOrderValue === sortOrder.asc) {
        return b.length - a.length
      }

      if (sortOrderValue === sortOrder.desc) {
        return a.length - b.length
      }

      return 0
    }
  },
  beforeMount() {
    this.$store.commit('units/RESET_SEARCH_UNITS')
  },

  beforeDestroy() {
    this.activeTab = ''
  },
  async created() {
    this.filterString = this.lastSearchKey
    this.searchOption = this.lastTypeSearch
  },

  mounted() {
    if (this.activeTabUnits === tabs.locators) {
      this.activeTab = tabs.locators
    } else if (this.activeTabUnits === tabs.trailers) {
      this.activeTab = tabs.trailers
    } else if (this.activeTabUnits === tabs.unitsgroups) {
      this.activeTab = tabs.unitsgroups
    }
  }
}
</script>
<style scoped>
.history-image {
  width: 18px;
  margin-right: 20px;
}
.skif-search {
  width: 225px;
}
.table-row {
  height: 38px;
}
.text-narrow {
  letter-spacing: -0.3px;
}
.text-dates {
  font-size: 11px;
}
.status-cell {
  width: 100px;
}
.button-square {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  font-size: 31px;
  font-weight: bold;
}
.button-delete {
  background-color: #fe6164;
  transition: all 0.2s ease-out;
}
.button-delete:hover {
  background-color: #cf4a4d;
}
.filter-locators {
  width: 320px !important;
}
.filter-container {
  width: 200px;
}
</style>
<style>
.confirmation {
  width: 466px;
  margin-left: 4.5rem;
}
.confirmation .el-notification__group {
  width: 100%;
}
.confirmation .el-notification__group .el-notification__content p div {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  font-size: 14px;
}
.confirmation .cancelConfirm {
  color: #91b4e7;
}
.el-popover {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  min-width: 160px;
}
.w-100 {
  width: 100%;
}
.fit-content {
  width: fit-content;
}
</style>
