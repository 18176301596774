export default {
  methods: {
    handleScrollUnits(event) {
      this.startIndexUnits = Math.round(
        event.target.scrollTop / this.cellHeight
      )
      this.topPaddingHeightUnits = Math.max(
        this.startIndexUnits * this.cellHeight,
        0
      )
      this.bottomPaddingHeightUnits = Math.max(
        this.totalHeightUnits - this.topPaddingHeightUnits - this.bufferHeight,
        0
      )
    },
    handleScrollUnitsgroups(event) {
      this.startIndexUnitsgroups = Math.round(
        event.target.scrollTop / this.cellHeight
      )
      this.topPaddingHeightUnitsgroups = Math.max(
        this.startIndexUnitsgroups * this.cellHeight,
        0
      )
      this.bottomPaddingHeightUnitsgroups = Math.max(
        this.totalHeightUnitsgroups -
          this.topPaddingHeightUnitsgroups -
          this.bufferHeight,
        0
      )
    },
    handleScrollTrailers(event) {
      this.startIndexTrailer = Math.round(
        event.target.scrollTop / this.cellHeight
      )
      this.topPaddingHeightTrailers = Math.max(
        this.startIndexTrailer * this.cellHeight,
        0
      )
      this.bottomPaddingHeightTrailers = Math.max(
        this.totalHeightTrailers -
          this.topPaddingHeightTrailers -
          this.bufferHeight,
        0
      )
    },
  },
  watch: {
    'filterDataUnits.length': function(val) {
      if (val < this.startIndexUnits) {
        this.startIndexUnits = 0
      }
      this.topPaddingHeightUnits = Math.max(
        this.startIndexUnits * this.cellHeight,
        0
      )
      this.totalHeightUnits = val * this.cellHeight
      this.bottomPaddingHeightUnits = Math.max(
        this.totalHeightUnits - this.topPaddingHeightUnits - this.bufferHeight,
        0
      )
    },
    'filterDataUnitgroups.length': function(val) {
      if (val < this.startIndexUnitsgroups) {
        this.startIndexUnitsgroups = 0
      }
      this.topPaddingHeightUnitsgroups = Math.max(
        this.startIndexUnitsgroups * this.cellHeight,
        0
      )
      this.totalHeightUnitsgroups = val * this.cellHeight
      this.bottomPaddingHeightUnitsgroups = Math.max(
        this.totalHeightUnitsgroups -
          this.topPaddingHeightUnitsgroups -
          this.bufferHeight,
        0
      )
    },
    'filterDataTrailers.length': function(val) {
      if (val < this.startIndexTrailer) {
        this.startIndexTrailer = 0
      }
      this.topPaddingHeightTrailers = Math.max(
        this.startIndexTrailer * this.cellHeight,
        0
      )
      this.totalHeightTrailers = val * this.cellHeight
      this.bottomPaddingHeightTrailers = Math.max(
        this.totalHeightTrailers -
          this.topPaddingHeightTrailers -
          this.bufferHeight,
        0
      )
    },
  },
  data() {
    return {
      startIndexUnits: 0,
      startIndexUnitsgroups: 0,
      startIndexTrailer: 0,

      cellHeight: 40,
      viewportHeight: 0,

      totalHeightUnits: 0,
      totalHeightUnitsgroups: 0,
      totalHeightTrailers: 0,

      bufferHeight: 0,
      tolerance: 5,
      toleranceHeight: 40 * 5,
      bufferedItems: 0,

      topPaddingHeightUnits: 0,
      topPaddingHeightUnitsgroups: 0,
      topPaddingHeightTrailers: 0,

      bottomPaddingHeightUnits: 0,
      bottomPaddingHeightUnitsgroups: 0,
      bottomPaddingHeightTrailers: 0,
    }
  },
  computed: {
    visibleItemsUnits() {
      return this.filterDataUnits.slice(
        this.startIndexUnits,
        this.startIndexUnits + this.bufferedItems
      )
    },
    visibleItemsUnitsgroups() {
      return this.filterDataUnitgroups.slice(
        this.startIndexUnitsgroups,
        this.startIndexUnitsgroups + this.bufferedItems
      )
    },
    visibleItemsTrailers() {
      return this.filterDataTrailers.slice(
        this.startIndexTrailer,
        this.startIndexTrailer + this.bufferedItems
      )
    },
  },
  mounted() {
    this.viewportHeight = this.$refs['scrolltable'].clientHeight

    this.totalHeightUnits = this.filterDataUnits.length * this.cellHeight

    this.totalHeightUnitsgroups =
      this.filterDataUnitgroups.length * this.cellHeight

    this.totalHeightTrailers = this.filterDataTrailers.length * this.cellHeight

    this.bufferHeight = this.viewportHeight + 2 * this.toleranceHeight
    this.bufferedItems = Math.round(
      this.viewportHeight / this.cellHeight + 2 * this.tolerance
    )
    this.bottomPaddingHeightUnits = Math.max(
      this.totalHeightUnits - this.bufferHeight,
      0
    )
    this.bottomPaddingHeightUnitsgroups = Math.max(
      this.totalHeightUnitsgroups - this.bufferHeight,
      0
    )
    this.bottomPaddingHeightTrailers = Math.max(
      this.totalHeightTrailers - this.bufferHeight,
      0
    )
  }
}
