export default {
  methods: {
    handleScrollLocators(event) {
      this.startIndexLocator = Math.max(
        Math.round(
          (event.target.scrollTop / this.cellHeightLocators) - 1
        ),
        0
      )

      this.topPaddingHeightLocators = Math.max(
        this.startIndexLocator * this.cellHeightLocators,
        0
      )
      this.bottomPaddingHeightLocators = Math.max(
        this.totalHeightLocators - this.topPaddingHeightLocators - this.bufferHeightLocators,
        0
      )
    },
  },
  watch: {
    'filterDataLocators.length': function(val) {
      if (val < this.startIndexLocator) {
        this.startIndexLocator = 0
      }
      this.topPaddingHeightLocators = Math.max(
        this.startIndexLocator * this.cellHeightLocators,
        0
      )
      this.totalHeightLocators = val * this.cellHeightLocators
      this.bottomPaddingHeightLocators = Math.max(
        this.totalHeightLocators - this.topPaddingHeightLocators - this.bufferHeightLocators,
        0
      )
    },
  },
  data() {
    return {
      startIndexLocator: 0,

      cellHeightLocators: 52,

      totalHeightLocators: 0,

      bufferHeightLocators: 0,
      toleranceLocators: 5,
      toleranceHeightLocators: 52 * 5,
      bufferedItemsLocators: 5,

      topPaddingHeightLocators: 0,

      bottomPaddingHeightLocators: 0,
    }
  },
  computed: {
    visibleItemsLocators() {
      return this.filterDataLocators.slice(
        this.startIndexLocator,
        this.startIndexLocator + this.bufferedItemsLocators
      )
    },
  },
  mounted() {
    this.totalHeightLocators = this.filterDataLocators.length * this.cellHeightLocators

    this.bufferHeightLocators = this.viewportHeight + 2 * this.toleranceHeightLocators
    this.bufferedItemsLocators = Math.round(
      this.viewportHeight / this.cellHeightLocators + 2 * this.toleranceLocators
    )
    this.bottomPaddingHeightLocators = Math.max(
      this.totalHeightLocators - this.bufferHeightLocators,
      0
    )
  }
}
