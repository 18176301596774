export const sortOrder = {
  asc: 'ascending',
  desc: 'descending'
}

export const sortPropertyUnits = {
  name: 'name',
  terminalType: 'terminal_type'
}

export const sortPropertyUnitsGroup = {
  name: 'name',
  units: 'units',
  users: 'users'
}

export const sortPropertyTrailers = {
  name: 'name',
  code: 'code'
}

export const sortPropertyLocators = {
  name: 'name',
  active: 'active'
}

export const tabs = {
  units: 'units',
  unitsgroups: 'unitsgroups',
  trailers: 'trailers',
  locators: 'locators'
}
